import React, {useState, useEffect, useRef} from 'react';
import useDynamicRefs from 'use-dynamic-refs';
import propTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import styled from 'styled-components';
import {get} from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import {Grid, Box, TextField, RadioGroup, FormControlLabel} from '@material-ui/core';
import {useForm, Controller} from "react-hook-form";
import isEmail from 'validator/lib/isEmail';
import isPostalCode from 'validator/lib/isPostalCode';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Router from 'next/router';

import Topo from '../../assets/topo-block-blue.png'
import Checkbox from '../../components/forms/Checkbox';
import Radio from '../../components/forms/Radio';
import FileUpload from '../../components/forms/FileUpload';
import Dropdown from '../../components/ui/Dropdown';
import Button from '../../components/ui/Cta';
import Text from '../../components/typography';
import {theme} from '../../themes/default-theme';

const Wrapper = styled.div`

`

const IntroContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 780px;
  margin-top: 40px;
  margin-bottom: 35px;

  h4 {
    margin-bottom: 20px;
  }

  ${theme.breakpoints.up('md')} {
    margin-top: 80px;
    margin-bottom: 50px;
  }

  ${theme.breakpoints.up('md')} {
    margin-top: 115px;
  }
  ${theme.breakpoints.down('sm')} {
    padding-top: 70px;
  }

`

const Icon = styled.div `
  margin-bottom: 20px;
  ${theme.breakpoints.up('md')} {
    margin-top: 25px;
  }
`

const FormWrapper = styled.div `
  background: ${props => props.background};
  ${props => props.padding === true && `
    padding: 20px 16px;
  `}
  position: relative;

  ${theme.breakpoints.up('md')} {
    ${props => props.padding === true && `
      padding: 110px 15px 60px;
    `}

    ${props => props.topo === true && `

      &:before {
        content: ' ';
        display: block;
        width: 345px;
        height: 224px;
        background-image: url(${Topo});
        position: absolute;
        background-size: 100% auto;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
        top: 50px;
        transform: translateX(-190px);
      }
    `}
  }
`

const Container = styled.div`
  ${props => props.background === 1 && `
    background-color: ${theme.palette.white};
  `}
  ${props => props.padding === true && `
    max-width: 570px;
    padding: 20px 16px;
  `}
  margin: 0 auto;
  
  border-bottom-left-radius: 30px;

  ${theme.breakpoints.up('md')} {
    ${props => props.padding === true && `
      padding: 52px 58px;
    `}
    border-bottom-left-radius: 60px;
  }
`

const Form = styled.form`
  text-align: left;
`

const FormItem = styled.div`
  display: block;
  margin:${props => props.margin === 'none' ? `0` : `30px 0`};
`

const Footer = styled.div`
  border-top: 1px solid ${theme.palette.middleGrey};
`

const Error = styled.p`
  color: ${theme.palette.error.form}
`

const Label = styled(Text) `

`

const CustomRadioGroup = styled(RadioGroup) `
  ${props => props.inline === 1 && `
    flex-direction: row;
  `};
`
  
const FormContainer = ({
  icon,
  title,
  formTitle,
  text,
  formItems,
  formId,
  formAction,
  thankYouUrl,
  padding,
  background,
  topo,
  onThankyou,
  sugarSubmit,
  formChangeValue
}) => {

  const countryCode = process.env.COUNTRY_CODE;

  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);
  const [getRef, setRef] =  useDynamicRefs();
  const [chassisRequired, setChassisRequired] = useState(false) // custom
  const [submitClick, setSubmitClick] = useState(false);
  const [formDataRevised, setFormDataRevised] = useState(formItems);
  const [formChange, setFormChange] = useState(false);

  let defaultValues = {}

  formDataRevised.forEach(item => {
    if (item.type === 'checkbox') {
      defaultValues[item.name] = item.defaultValue || false
    }
  });

  const { register, handleSubmit, watch, errors, control, formState } = useForm({
    defaultValues
  });

  const enquiryField = (formId === 'enquiry') ? watch('enquiry_type') : null; // custom
  const chassisField = (formId === 'enquiry') ? watch('chassis') : null; // custom

  useEffect(() => {
    const required = enquiryField && enquiryField.value && (enquiryField.value.toLowerCase().indexOf('sales') === -1) ? true : false
    setChassisRequired(required); // custom
  }, [enquiryField])

  useEffect(() => {
    const firstError = formDataRevised.find(item => {
      return errors[item.name]
    });
    if (firstError) {
      const firstItem = getRef(firstError.name);
      const yOffset = -100;
      const y = firstItem.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }, [errors])

  const [googleCaptcha, setGoogleCaptcha] = useState(false);

  const onSubmit = data => {

    // custom
    if (formId === 'enquiry' && chassisRequired && !chassisField) {
      const yOffset = -100;
      const chassis = getRef('chassis');
      const y = chassis.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      return
    }
    
    // if (sugarSubmit && data.email) {
    //   const path = thankYouUrl ? thankYouUrl : window.location.pathname;
    //   const rurl = path.indexOf('http') > 0 ? path : `${window.location.origin}${path}`;
    //   console.info(`Redirecting to: http://marketing.jayco.com.au/RESTForm.aspx?Customer=ch000097811eArad&cke=1&ownerid=78&overwrite=0&PushExternal=1&rurl=${rurl}&Email=${data.email}&submit=Submit+Query`)
    //   // window.location.href = `http://marketing.jayco.com.au/RESTForm.aspx?Customer=ch000097811eArad&cke=1&ownerid=78&overwrite=0&PushExternal=1&rurl=${rurl}&Email=${data.email}&submit=Submit+Query`
    //   return
    // }

    const recaptchaValue = recaptchaRef.current.getValue();

    let refatorDropdowns = {}

    formDataRevised.forEach(item => {
      if (item.type === 'select') {
        refatorDropdowns[item.name] = data[item.name] && data[item.name].value ? data[item.name].value : undefined
      }
    });

    const dealerId = process.env.DEALER_SITE && window.staticly.dealerData.id ? {
      dealer: window.staticly.dealerData.id
    } : {}

    const formData = {
      ...data,
      ...refatorDropdowns,
      'g-recaptcha-response': googleCaptcha,
      form_id: formId,
      ...dealerId
    }

    console.log('formData: ', formData);

    let bodyFormData = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === "files") {
        for(var i = 0; i < formData[key][""].length; i++){
          let item = formData[key][""][i];
          bodyFormData.append('files[' + i + ']', item.file);
        }
      } else {
        bodyFormData.append(key, formData[key].toString() || '');
      }
    });

    console.log('bodyFormData: ', bodyFormData);
    if (googleCaptcha && recaptchaValue.length > 0 && Object.keys(errors).length === 0) {
      setCaptchaError(null);

      const endpoint = formAction ? formAction : process.env.STATICLY_FORMS_API_ENDPOINT;

      setSending(true);

      // axios.post(endpoint, formData)
      axios({
        method: 'post',
        url: endpoint,
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then((response) => {

        if (sugarSubmit && data.email) {
          const path = thankYouUrl ? thankYouUrl : window.location.pathname;
          const rurl = path.indexOf('http') === 0 ? path : `${window.location.origin}${path}`;
          console.info('rurl', rurl);
          console.info(`Redirecting to: http://marketing.jayco.com.au/RESTForm.aspx?Customer=ch000097811eArad&cke=1&ownerid=78&overwrite=0&PushExternal=1&rurl=${rurl}&Email=${data.email}&submit=Submit+Query`)
          window.location.href = `http://marketing.jayco.com.au/RESTForm.aspx?Customer=ch000097811eArad&cke=1&ownerid=78&overwrite=0&PushExternal=1&rurl=${rurl}&Email=${data.email}&submit=Submit+Query`
          
        } else {
          if (thankYouUrl === 'inline') {
            onThankyou();
          } else {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            process.env.NODE_ENV === 'development' || process.env.DEALER_SITE ?
              window.location.href = thankYouUrl
            :
            Router.push(thankYouUrl);
          }
        }

      })
      .catch((error) => {
        console.log('Form error: ', error);
        setError('Something went wrong. Please try again later.');
        setSending(false);
      });

    } else {
      setCaptchaError('Google captcha not complete');
      setSending(false)
    }
  }

  const checkEmail = (val, e) => {
    if (e.required || val.length > 0) {
      return isEmail(val)
    }
    return true
  }

  const handleVisibility = (e, i) => {
    console.log(e, i);
    if (e == i[1]) {
      formItems[i[0]].visible = true;
    } else {
      formItems[i[0]].visible = false;
    }

    setFormChange(!formChange);
  }

  useEffect(() => {
    setFormDataRevised(formItems);
  }, [formChange])

  const recaptchaRef = useRef();
  

  return (
  	<Wrapper>
      {(icon || title || text) &&
        <IntroContainer>
          <Grid container direction={'row'} justify={'space-between'} align={'center'}>
            <Grid item xs={12}>
              <Box px={2}>
                {icon &&
                  <Icon>
                    <ReactSVG src={icon} />
                  </Icon>
                }
                {title &&
                  <Text variant="h4" align="center" uppercase colour="charcoal">{ReactHtmlParser(title)}</Text>
                }
                {text &&
                  <Text variant="body1" align="center">{ReactHtmlParser(text)}</Text>
                }
              </Box>
            </Grid>
          </Grid>
        </IntroContainer>
      }

      <FormWrapper
        padding={padding}
        background={background}
        topo={topo}
      >
        <Container
          padding={padding}
          background={background === 'transparent' ? 0 : 1}
        >
          {formTitle &&
            <Text variant="h4" align="center" uppercase colour="charcoal">{formTitle}</Text>
          }
          <Form id={formId} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {formDataRevised && formDataRevised.map((item, index) => {
              const error = get(errors, `[${item.name}]`, false);
              const rules = item.required ? { required: true } : {}
                switch(item.type) {
                  case 'hidden' :
                  return (
                    <input
                      key={index}
                      type="hidden"
                      name={item.name}
                      ref={register} 
                      value={item.value || ''}
                    />
                  )
                }
                switch(item.type) {
                  case 'label' :
                  return (
                    <FormItem key={index}>
                      <Text variant="subtitle1" align="left" uppercase colour="charcoal">{item.label}</Text>
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'input' :
                  if (formId === 'enquiry' && item.name === 'chassis') {
                    return (
                      <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                        <Controller
                          as={TextField}
                          control={control}
                          rules={rules}
                          name={item.name}
                          placeholder={chassisRequired ? `${item.placeholder}*` : item.placeholder}
                          variant="outlined"
                          error={(submitClick && chassisRequired && !chassisField) || (submitClick && chassisRequired && chassisField.length === 0) ? true : false}
                          fullWidth
                          defaultValue={item.defaultValue || ''}
                        />
                        {((submitClick && chassisRequired && !chassisField) || (submitClick && chassisRequired && chassisField.length === 0)) && item.errorText && <Error>{item.errorText}</Error>}
                      </FormItem>
                    )
                  } else {
                    return (
                      <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                        <Controller
                          as={TextField}
                          control={control}
                          rules={rules}
                          name={item.name}
                          placeholder={item.placeholder}
                          variant="outlined"
                          error={error ? true : false}
                          fullWidth
                          defaultValue={item.defaultValue || ''}
                        />
                        {error && item.errorText && <Error>{item.errorText}</Error>}
                      </FormItem>
                    )
                  }
                }
                switch(item.type) {
                  case 'name' :
                  return (
                    <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                      <Controller
                        as={TextField}
                        control={control}
                        rules={rules}
                        name={item.name}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                      />
                      {error && item.errorText && <Error>{item.errorText}</Error>}
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'email' :
                  return (
                    <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                      <Controller
                        type="email"
                        as={TextField}
                        control={control}
                        rules={{
                          ...rules,
                          type: 'custom',
                          validate: (e) => checkEmail(e, item)
                        }}
                        name={item.name}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                      />
                      {error && item.errorText && <Error>{item.errorText}</Error>}
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'phone' :
                  return (
                    <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                      <Controller
                        type="tel"
                        as={TextField}
                        control={control}
                        rules={{
                          ...rules,
                          type: 'custom',
                          minLength: 8,
                          maxLength: 13
                        }}
                        name={item.name}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                      />
                      {error && item.errorText && <Error>{item.errorText}</Error>}
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'postcode' :
                  return (
                    <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                      <Controller
                        type="tel"
                        as={TextField}
                        control={control}
                        rules={{
                          ...rules,
                          type: 'custom',
                          minLength: 3,
                          maxLength: 4,
                          validate: (val) => isPostalCode(val, 'AU')
                        }}
                        name={item.name}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                      />
                      {error && item.errorText && <Error>{item.errorText}</Error>}
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'select' :
                  return (
                    <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                      <Controller
                        as={
                          <Dropdown 
                            variant="outlined"
                            showComplete={true}
                            error={error ? true : false}
                            label={item.label}
                            options={item.options.map((item, i) => {
                              return {
                                id: `dropdown_${index}_${i}`,
                                value: item.optionValue,
                                text: item.label,
                              }
                            })}
                            
                            defaultValue={item.defaultValue || undefined}
                          />
                        }
                        // onChange={(e) => console.log('Dropdown has changed: ', e)}
                        control={control}
                        rules={{
                          ...rules,
                          type: 'custom'
                        }}
                        name={item.name}
                      />
                      {error && item.errorText && <Error>{item.errorText}</Error>}
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'fileupload' :
                  return (
                    item.visible &&
                      <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                        <Controller
                          as={
                            <FileUpload 
                              defaultValue={item.defaultValue || undefined}
                            />
                          }
                          control={control}
                          rules={{
                            ...rules,
                            type: 'custom'
                          }}
                          name={item.name}
                        />
                      </FormItem>
                  )
                }
                switch(item.type) {
                  case 'textarea' :
                  return (
                    <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                      <Controller
                        as={TextField} 
                        control={control}
                        rules={rules}
                        name={item.name}
                        type="textarea"
                        multiline={true}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                        rows={item.rows || 5}
                      />
                      {error && item.errorText && <Error>{item.errorText}</Error>}
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'checkbox' :
                  return (
                    <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                      <Controller
                        as={Checkbox}
                        name={item.name}
                        type="checkbox"
                        control={control}
                        label={item.label}
                        error={error ? true : false}
                        rules={{
                          ...rules,
                          type: 'custom'
                        }}
                      />
                      {error && item.errorText && <Error>{item.errorText}</Error>}
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'radio' : {
                    const defaultVal = item.options.find(i => i.value === 'on');
                    return (
                      <FormItem key={index} margin={item.margin} ref={setRef(item.name)}>
                        {item.label && item.showLabel &&
                         <Label>{item.label}</Label>
                        }
                        <Controller
                          control={control}
                          rules={{
                            ...rules,
                            type: 'custom'
                          }}
                          name={item.name}
                          label={item.label}
                          as={
                            <CustomRadioGroup
                              defaultValue={defaultVal && defaultVal.optionValue || undefined}
                              aria-label={item.label}
                              name={item.label}
                              inline={item.inline ? 1 : 0}
                              onChange={(event) => console.log('Radio val', event.target.value)}
                            >
                            {item.options.map((radio, index) => (
                              <FormControlLabel key={radio.optionValue} value={radio.optionValue} control={
                                <Radio 
                                  value={radio.optionValue}
                                  error={error ? true : false}
                                  onChange={item.onVisibilityIndex && ((e) => handleVisibility(radio.optionValue, item.onVisibilityIndex))}
                                />
                              } label={radio.label} />
                            ))}
                            </CustomRadioGroup>
                          }
                        />
                        {error && item.errorText && <Error>{item.errorText}</Error>}
                      </FormItem>
                    )
                  }
                }
              })}
              <FormItem>
                {process.env.GOOGLE_RECAPTCHA_SITE_KEY &&
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={setGoogleCaptcha}
                  />
                }
                {!googleCaptcha && captchaError &&
                  <Error>{captchaError}</Error>
                }
                {error &&
                  <Error>{error}</Error>
                }
              </FormItem>
              <Footer>
                <FormItem>
                  <Grid container direction={'row'} justify={'space-between'} align={'center'}>
                    <Grid item xs={6}>
                      <Text variant="body2">Mandatory*</Text>
                    </Grid>
                    <Grid item xs={6} align={'right'}>
                      <Button inline type="submit" onClick={() => setSubmitClick(true)}>{sending ? 'Submitting...'  : 'Submit'}</Button>
                    </Grid>
                  </Grid>
                </FormItem>
              </Footer>
          </Form>
        </Container>
        
      </FormWrapper>
      
    </Wrapper>
  )
}
  
FormContainer.propTypes = {
  icon: propTypes.string,
  title: propTypes.string,
  formTitle: propTypes.string,
  text: propTypes.string,
  formAction: propTypes.string,
  thankYouUrl: propTypes.string.isRequired,
  formId: propTypes.string.isRequired,
  padding: propTypes.bool,
  background: propTypes.string,
  topo: propTypes.bool,
  onThankyou: propTypes.func
}
  
FormContainer.defaultProps = {
  icon: null,
  title: null,
  formTitle: null,
  text: null,
  formAction: null,
  thankYouUrl: 'thank-you',
  formId: '',
  padding:true,
  background: theme.palette.skyGradientLight,
  topo:true,
  onThankyou: () => {}
}
  
export default FormContainer